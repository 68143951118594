module.exports = [{
      plugin: require('../../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":650,"linkImagesToOriginal":true,"showCaptions":false,"markdownCaptions":false,"sizeByPixelDensity":false,"backgroundColor":"white","quality":50,"withWebp":false,"tracedSVG":false,"loading":"lazy","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../../node_modules/@tomato-ui/gatsby-plugin-nprogress/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../node_modules/@tomato-ui/gatsby-plugin-tomato-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[],"component":"/home/runner/work/stoicbyte.com/stoicbyte.com/node_modules/@tomato-ui/gatsby-theme-layout/src/index.js"},
    },{
      plugin: require('../../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"extensions":[".mdx"],"remarkPlugins":[null,null,null,null,null,null],"gatsbyRemarkPlugins":[{"resolve":"gatsby-remark-embedder"},{"resolve":"gatsby-remark-images"}],"defaultLayouts":{},"lessBabel":false,"rehypePlugins":[],"mediaTypes":["text/markdown","text/x-markdown"]},
    },{
      plugin: require('../../node_modules/@tomato-ui/gatsby-plugin-seo/gatsby-browser.js'),
      options: {"plugins":[],"siteMetadata":{"title":"StoicByte","description":"Tech","author":"@iamvishnusankar","site":"@stoicbyte","siteUrl":"https://www.stoicbyte.com/","canonical":"https://www.stoicbyte.com/","logo":"../images/logo.png","icon":"contents/images/logo.png","defaultAuthorId":"iamvishnusankar","authors":[{"id":"iamvishnusankar","name":"Vishnu Sankar","picture":"https://avatars3.githubusercontent.com/u/4602725?s=460","type":"Person"}],"twitter":{"cardType":"summary_large_image","site":"@stoicbyte","handle":"@stoicbyte"},"openGraph":{"url":"https://www.stoicbyte.com/","type":"website","title":"StoicByte","description":".","site_name":"StoicByte"},"links":{"navbar":[{"to":"/blogs","label":"Blogs","position":"left"}]}},"robotsTxtOptions":{}},
    },{
      plugin: require('../../node_modules/gatsby-plugin-instagram-embed/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../node_modules/gatsby-plugin-pinterest/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../node_modules/gatsby-plugin-twitter/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"StoicByte","start_url":"/","background_color":"#f7f0eb","theme_color":"#a2466c","display":"standalone","icon":"contents/images/logo.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"b6390fda5751964103a6988c62256e9d"},
    },{
      plugin: require('../../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
